<template>
    <div id="app">
        <Homepage/>
    </div>
</template>

<script>
import Homepage from './components/Homepage.vue'

export default {
    name: 'App',
    components: {Homepage}
}
</script>

<style>
#app {
    font-family: Verdana, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background-color: aliceblue;
    height: 100vh;
}

body {
    margin: 0;
}
</style>
